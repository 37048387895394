<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <div class="cons">
      <div class="xqmain">
        <div class="xqmain-maintitle">
          <div>论文优化</div>
          <div>
            <el-button type="primary" @click="comeback">重新提交</el-button>
          </div>
        </div>

        <div class="dxwx-con">
          <div class="dxwx-list">
            <div class="dxwx-list-title">标题：</div>
            <div class="dxwx-list-con">{{ resobj.btword }}</div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">摘要：</div>
            <div class="dxwx-list-con">{{ resobj.zyword }}</div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">关键词：</div>
            <div class="dxwx-list-con">{{ resobj.kwword }}</div>
          </div>
        </div>

        <div class="dxwx-con tgfx">
          <div class="dxwx-list" v-loading="loading" element-loading-text="标题优化中..." element-loading-background="rgba(255, 255, 255, 0)">
            <div class="dxwx-list-title">标题优化：</div>
            <div class="dxwx-list-title-btn" v-if="isbiaotiyouhua">
              <el-button
                type="warning"
                size="small"
                @click="
                  isbiaotiyouhua = !isbiaotiyouhua;
                  biaotiyouhua();
                "
                >立即优化</el-button
              >
            </div>
            <div
              class="dxwx-list-con"
              v-if="btyhlist.length > 0 && !isbiaotiyouhua"
            >
              <div class="btyh-list" v-for="(item, key) in btyhlist" :key="key">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">关键词发掘：</div>
            <div class="dxwx-list-con gkcfjue">
              <div class="dxwx-list-con-gjc">{{ gjcfj }}</div>
            </div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">中图分类号匹配：</div>
            <div class="dxwx-list-con ztflhpp">
              <div
                class="ztflhpp-list"
                v-for="(item, key) in ztflhtabledata"
                :key="key"
              >
                {{ item.flhname }}（{{ item.flh }}）{{
                  ztflhtabledata.length == key + 1 ? "" : "，"
                }}
              </div>
            </div>
          </div>
          <div class="dxwx-list">
            <div class="dxwx-list-title">参考文献推荐：</div>
            <div class="dxwx-list-con">
              <div
                class="dxwx-list-con-ckwx"
                v-for="(item, key) in ckwxlist"
                :key="key"
              >
                [{{ key + 1 }}]{{ item.writerwenxian }}.{{
                  item.arttitle
                }}[J].{{ item.qkname }},{{ item.year }}({{ item.qs }}):{{item.wzpagefw}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { get, post } from "@/utils/index";
import { useRouter } from "vue-router";
const router = useRouter();

let loading=ref(false)
let resobj = reactive({});
let ztflhtabledata = reactive([]);
// 标题优化
let btyhlist = reactive([]);
// 参考文献列表
let ckwxlist = reactive([]);
// 关键词
let gjcfj = ref("");
// 是否显示标题优化
let isbiaotiyouhua = ref(true);

// 获取投稿发现
const gettgfx = () => {
  get("/journalRecommend/GetBynidShort", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res);
    Object.assign(resobj, res.data);
    ztflhtabledata.push(...JSON.parse(resobj.ztflhstr));
    btyhlist.length = 0;

    // 关键词发掘
    JSON.parse(resobj.xgqk).forEach((e, i) => {
      if (i < JSON.parse(resobj.xgqk).length - 1) {
        gjcfj.value += e.kw + ",";
      } else {
        gjcfj.value += e.kw;
      }
    });
  });
};
gettgfx();
// 获取标题优化
const biaotiyouhua = () => {
  // 不存在标题
  if (resobj.lwqkinfo == "") {
    loading.value=true
    // 分词
    post("/journalCommon/SubWordCut", {
      key: resobj.btword,
      kt: "",
      yxkey: "",
    }).then((result) => {
      // console.log(result);
      let kwword = "";
      if (result.data && result.data.length > 0) {
        result.data.forEach((e, i) => {
          if (i < result.data.length - 1) {
            kwword += e + ",";
          } else {
            kwword += e;
          }
        });
      }
      // 重新生成标题
      get("/journalRecommend/GetBynidArt", {
        id: router.currentRoute.value.query.id,
        str: kwword,
      }).then((rs) => {
        loading.value=false
        btyhlist.push(...rs.data.split("\n\n"));
      });
    });
  } else {
    btyhlist.push(...resobj.lwqkinfo.split("\n\n"));
  }
};

// 获取参考文献
const getckwx = () => {
  get("/journalRecommend/GetBynidTgfx", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    ckwxlist.push(...res.data);
    ckwxlist.forEach((e) => {
      e.writerwenxian = "";
      e.writer.split(";").forEach((ele, key) => {
        if (e.writer.split(";").length > 3) {
          if (key < 2) {
            e.writerwenxian += ele + ",";
          }
          if (key == 2) {
            e.writerwenxian += ele + "等";
          }
        } else {
          if (key < e.writer.split(";").length - 1) {
            e.writerwenxian += ele + ",";
          } else {
            e.writerwenxian += ele;
          }
        }
      });

      // e.writer.split(";").forEach((ele, key) => {
      //   if (key < 2) {
      //     e.writerwenxian += ele + ",";
      //   }
      //   if (key == 2) {
      //     e.writerwenxian += ele + "等";
      //   }
      // });
    });
  });
};
getckwx();
// 返回
const comeback = () => {
  router.push({
    path: "/lwyh/sbu",
    query: {},
  });
};
</script>

<style scoped>
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0 50px 0;
}
.xqmain-maintitle {
  border-bottom: 3px double #333;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.xqmain-title {
  font-size: 24px;
}
.xqmain-subtitle {
  padding: 20px 0;
}
.xqmain-subtitle-zuozhe {
  color: #2466b1;
}
.xqmain-subtitle-org {
  color: #999;
  padding: 0 20px;
}
.xqmain-subtitle-qs {
  color: #999;
}
.xqmain-zhaiyao {
  padding: 20px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
  line-height: 32px;
}
.xqmain-zhaiyao > span {
  font-weight: bold;
}
.tips {
  color: #999;
  font-size: 12px;
  padding-top: 20px;
}
.dxwx-con {
  margin-top: 15px;
  font-size: 14px;
  padding: 10px 20px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
}
.dxwx-list {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.dxwx-list-title {
  width: 120px;
  line-height: 20px;
  font-weight: bold;
}
.dxwx-list-con {
  width: calc(100% - 120px);
  min-height: 40px;
  line-height: 20px;
}
.gkcfjue::after {
  content: "";
  display: table;
  clear: both;
}
.xswx {
  padding: 20px;
  margin-top: 10px;
}
.xswx-title {
  font-weight: bold;
  padding-bottom: 10px;
}
.xswx-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  line-height: 20px;
  padding: 5px 0;
  border-bottom: 1px dashed #ddd;
  cursor: pointer;
}
.xswx-li:hover {
  background: #f2f2f2;
}
.dxwx-list-con-ckwx {
  padding: 0 0 2px 0;
}
.dxwx-list-con-gjc {
  float: left;
}
.dxwx-list-title-btn {
  width: calc(100% - 120px);
}
.ztflhpp {
  display: flex;
}
</style>
